.cargo {
    background: #4BA5DA url(../../img/main-bg.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 65px;
    margin-bottom: 88px;
    //filter: drop-shadow(0px 17px 60px rgba(32, 32, 32, 0.11));

    &-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 65px;
    }
    &__text {
        margin-top: 50px;
        h1 {
            font-weight: 600;
            font-size: 52px;
            line-height: 104%;
            /* or 54px */
            max-width: 500px;
            letter-spacing: -1.5px;
            margin-bottom: 17px;
            color: #ffffff;
            span {
                font-style: italic;
                font-weight: 800;
                font-size: 52px;
                line-height: 104%;
                /* or 54px */

                letter-spacing: -0.5px;

                color: #ffffff;
            }
        }
        h2 {
            font-size: 18px;
            line-height: 114.5%;
            /* or 25px */
            max-width: 400px;
            letter-spacing: -1.5px;
            margin-bottom: 25px;
            color: #ffffff;
        }
    }
    &__img {
        width: auto;
        height: auto;
        border-radius: 20px;
        position: relative;
        flex: 2;
        svg{
            position: absolute;
            top: 32px;
            left: 28px;
            width: 212px;
            height: 55px;
        }
        img {
            width: 100%;
            border-radius: 20px;
            //height: 100%;
            //object-fit: contain;
        }
    }
}
@media (max-width: 1400px) {
    .cargo__text h1 {
        font-weight: 600;
        font-size: 30px;
        line-height: 104%;
        max-width: 500px;
        letter-spacing: -1.5px;
        margin-bottom: 12px;
        color: #ffffff;
        span {
            font-size: 30px;
            line-height: 104%;
        }
    }
    .cargo{
        padding: 35px;
        margin-bottom: 44px;
    }
    .cargo-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 35px;
    }
    .cargo__text {
        margin-top: 0;
    }
    .cargo__img {
        width: 50%;
        height: auto;
        border-radius: 20px;
        //img {
        //    width: 90%;
        //    height: auto
        //}
        svg{
            display: none;
            position: absolute;
            top: 32px;
            left: 28px;
            width: 212px;
            height: 55px;
        }
    }
    .cargo{
        background-position: top -109px left -324px;
        background-size: auto;
    }
}
