.container {
    max-width: 1400px;
    margin: 0 auto;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
    font-family: "Montserrat", sans-serif;
}
.title {
    white-space: pre-line;
    font-weight: 700;
    font-size: 44px;
    line-height: 104%;
    /* identical to box height, or 46px */
    padding-bottom: 35px;
    letter-spacing: -1.5px;

    color: #505457;
}
.pc{
    display: block !important;
}
#open .opened{
    display: none;
}
#open .closed{
    display: block !important;
}
.closedbutton .closed{
    display: none;
}
.mob{
    display: none !important;
}
.mobf{
    display: none !important;
}
@media (max-width: 1400px) {
    .title {
        text-align: center;
    }
}
.offer {
    padding-top: 88px;
}
iframe {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
@media (max-width:920px){
    .cargo {
        padding: 35px 0 0  !important;

        .cargo__text h2 {
          font-size: 16px;
        }
    }
    .title {
        text-align: left ;
    }
    .container{
        padding: 0 24px !important;
    }
    .mob{
        display: block !important;
    }
    .mobf{
        display: flex !important;
    }
    .pc{
        display: none !important;
    }
    .pcf{
        display: none !important;
    }
    .titlew{
        font-weight: 700;
        font-size: 30px;
        line-height: 104%;
        /* identical to box height, or 31px */

        letter-spacing: -1.5px;
        padding-bottom: 28px !important;
        color: #FFFFFF;

    }
    .title{
        font-weight: 700;
        font-size: 30px;
        line-height: 104%;
        /* or 31px */

        letter-spacing: -1.5px;

        color: #505457;

    }
}

